<template>
<svg version="1.1" id="Calque_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	 x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" class="svg-icon">
<path class="st0" d="M502.3,159.7l-234-156c-8-4.9-16.5-5-24.6,0l-234,156c-6,4-9.7,11.1-9.7,18.3v156c0,7.1,3.7,14.3,9.7,18.3
	l234,156c8,4.9,16.5,5,24.6,0l234-156c6-4,9.7-11.1,9.7-18.3V178C512,170.8,508.3,163.7,502.3,159.7L502.3,159.7z M278,63.1
	L450.3,178l-76.9,51.4L278,165.7V63.1z M234,63.1v102.6l-95.4,63.7L61.7,178L234,63.1z M44,219.1L99.1,256L44,292.8V219.1L44,219.1z
	 M234,448.8L61.7,334l76.9-51.4l95.4,63.7V448.8z M256,308l-77.7-52l77.7-52l77.7,52L256,308z M278,448.8V346.3l95.4-63.7l76.9,51.4
	L278,448.8z M468,292.8L412.9,256l55.1-36.9V292.8L468,292.8z"/>
<path class="st1" d="M457.2,421.9H54.8c-17.8,0-32.2-14.4-32.2-32.2V120.1c0-17.8,14.4-32.2,32.2-32.2h402.5
	c17.8,0,32.2,14.4,32.2,32.2v269.5C489.5,407.5,475.1,421.9,457.2,421.9z"/>
<polyline class="st2" points="68.6,141 256.9,254.9 443.4,141 "/>
<line class="st3" x1="223.7" y1="234.8" x2="77" y2="364.9"/>
<line class="st3" x1="291.5" y1="234.8" x2="438.2" y2="364.9"/>
</svg>

</template>

<script>
    export default {
        name: 'ContactSVG'
    }
</script>

<style type="text/css">
	.st0{display:none;fill:#72D3E1;stroke:#6CC4C7;stroke-miterlimit:10;}
	.st1{fill:none;stroke:#72D3E1;stroke-width:44.9269;stroke-miterlimit:10;}
	.st2{fill:none;stroke:#72D3E1;stroke-width:40.9553;stroke-linecap:round;stroke-miterlimit:10;}
	.st3{fill:none;stroke:#72D3E1;stroke-width:38.1115;stroke-linecap:round;stroke-miterlimit:10;}
</style>