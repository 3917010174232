<template>
    <header>
        <div class="navigation" :class="[{sticky:active}]" v-if="$windowWidth > 767">
            <nav class="row">
                <ul>
                    <li :class="[this.$route.name == 'Home' ? 'active' : '']">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </li>
                    <li :class="[this.$route.name == 'Portfolio' ? 'active' : '']">
                        <router-link :to="{ name: 'Portfolio' }">Portfolio</router-link>
                    </li>
                    <li :class="[this.$route.name == 'Cv' ? 'active' : '']">
                        <router-link :to="{ name: 'Cv' }">C.V.</router-link>
                    </li>
                    <!--<li :class="[this.$route.name == 'Contact' ? 'active' : '']">
                        <router-link :to="{ name: 'Contact' }">Contact</router-link>
                    </li>-->
                </ul>
            </nav>
        </div>
        <div class="navigation-mobile" v-else>
          <nav>
                <ul :class="[this.$route.name == 'Portfolio' ? 'active active-portfolio' : '', this.$route.name == 'Home' ? 'active active-home' : '' , this.$route.name == 'Contact' ? 'active active-contact' : '', this.$route.name == 'Cv' ? 'active active-cv' : '']">
                     <li :class="[this.$route.name == 'Home' ? 'active' : '']">
                        <router-link :to="{ name: 'Home' }"><HomeSVG /><p>Home</p></router-link>
                    </li>
                     <li :class="[this.$route.name == 'Portfolio' ? 'active' : '']">
                        <router-link :to="{ name: 'Portfolio' }"><PortfolioSVG /><p>Portfolio</p></router-link>
                    </li>
                    <li :class="[this.$route.name == 'Cv' ? 'active' : '']">
                        <router-link :to="{ name: 'Cv' }"><ContactSVG /><p>C.V.</p></router-link>
                    </li>
                   <!-- <li :class="[this.$route.name == 'Contact' ? 'active' : '']">
                        <router-link :to="{ name: 'Contact' }"><ContactSVG /><p>Contact</p></router-link>
                    </li> -->
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
    import HomeSVG from '@/assets/svg/home.vue'
    import PortfolioSVG from '@/assets/svg/portfolio.vue'
    import ContactSVG from '@/assets/svg/contact.vue'
    export default {
        name: 'Nav',
        components : { HomeSVG, PortfolioSVG, ContactSVG},
        props: {
            current: String
        },

        data() {
        return {
              active: false,
            }
          },
        created () {
            window.addEventListener('scroll', this.toggleNavClass);
          },
        methods: {
            toggleNavClass(){
                if(this.$windowWidth > 768) {
                let navBar = document.querySelector('.navigation');
                if(window.scrollY > navBar.offsetHeight){
                    this.active = true;
                    } else {
                    this.active = false;
                  }
              }
            },
          },
            

    }
</script>
