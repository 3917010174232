import { createStore } from "vuex";

export default createStore({
  state: {
    projects: [
      {
        slug: "how-easy",
        id: 1,
        tags: ["Wordpress","HTML","CSS","JavaScript","WebDesign"],
        label: "How Easy",
        imgmin: require("@/assets/img/projects/howeasy/howeasy-min.png"),
        imgvert: require("@/assets/img/projects/howeasy/howeasy-vert.png"),
        imghrz: require("@/assets/img/projects/howeasy/howeasy-hrz.png"),
        imglong: require("@/assets/img/projects/howeasy/howeasy-vert.png"),
        imgdesc: require("@/assets/img/projects/howeasy/howeasy-desc.png"),
        imgbody: [
          require("@/assets/img/projects/howeasy/howeasy-desc.png"),
          require("@/assets/img/projects/howeasy/howeasy1.png"),
          require("@/assets/img/projects/howeasy/howeasy2.png"),
          require("@/assets/img/projects/howeasy/howeasy3.png"),
          require("@/assets/img/projects/howeasy/howeasy4.png"),
        ],
        desc: ""
      },
      {
        slug: "sanela",
        id: 2,
        tags: ["Wordpress","HTML","CSS","JavaScript","WebDesign"],
        label: "Massage Sanela",
        imgmin: require("@/assets/img/projects/massagesanela/massagesanela-min.png"),
        imgvert: require("@/assets/img/projects/massagesanela/massagesanela-vert.png"),
        imghrz: require("@/assets/img/projects/massagesanela/massagesanela-hrz.png"),
        imglong: require("@/assets/img/projects/massagesanela/massagesanela-vert.png"),
        imgdesc: require("@/assets/img/projects/massagesanela/massagesanela-desc.png"),
        imgbody: [
          require("@/assets/img/projects/massagesanela/massagesanela-desc.png"),
          require("@/assets/img/projects/massagesanela/massagesanela1.png"),
          require("@/assets/img/projects/massagesanela/massagesanela2.png"),
          require("@/assets/img/projects/massagesanela/massagesanela3.png"),
          require("@/assets/img/projects/massagesanela/massagesanela4.png"),
        ],
      },
      {
        slug: "myhoraires",
        id: 3,
        tags: ["React Native","HTML","CSS","JavaScript","Figma","WebDesign"],
        label: "MyHoraires",
        imgmin: require("@/assets/img/projects/myhoraires/myhoraires-min.png"),
        imgvert: require("@/assets/img/projects/myhoraires/myhoraires-min.png"),
        imghrz: require("@/assets/img/projects/myhoraires/myhoraires-min.png"),
        imglong: require("@/assets/img/projects/myhoraires/myhoraires-long.png"),
        imgdesc: require("@/assets/img/projects/myhoraires/myhoraires-min.png"),
        imgbody: [
          require("@/assets/img/projects/myhoraires/myhoraires-min.png"),
          require("@/assets/img/projects/myhoraires/myhoraires-long.png"),
        ],
        links: [
          {
            label : "Découvrir le Figma",
            url : "https://www.figma.com/design/IAEOjtmBodqCcnnVjFSGOI/MyHoraires?node-id=0-1&t=4ZIGAFKTSeWB7vcI-1"
          }
        ],
        desc: "Création d'une application mobile à l'aide de React Native permettant de gérer ses horaires. Affichage des horaires du jour sur l'écran d'accueil du téléphone via un widget."
      },
      {
        slug: "az-transport",
        id: 4,
        tags: ["Wordpress","HTML","CSS","JavaScript"],
        label: "AZ Transport Déménagement",
        imgmin: require("@/assets/img/projects/az-demenagement/az-demenagement-min.png"),
        imgvert: require("@/assets/img/projects/az-demenagement/az-demenagement-vert.png"),
        imghrz: require("@/assets/img/projects/az-demenagement/az-demenagement-hrz.png"),
        imglong: require("@/assets/img/projects/az-demenagement/az-demenagement-vert.png"),
        imgdesc: require("@/assets/img/projects/az-demenagement/az-demenagement-desc.png"),
        imgbody: [
          require("@/assets/img/projects/az-demenagement/az-demenagement-desc.png"),
          require("@/assets/img/projects/az-demenagement/az-demenagement1.png"),
          require("@/assets/img/projects/az-demenagement/az-demenagement2.png"),
          require("@/assets/img/projects/az-demenagement/az-demenagement3.png"),
          require("@/assets/img/projects/az-demenagement/az-demenagement4.png"),
        ],
      },
      {
        slug: "alpwise",
        id: 5,
        tags: ["Wordpress","HTML","CSS","JavaScript","WebDesign"],
        label: "Alpwise",
        imgmin: require("@/assets/img/projects/alpwise/alpwise-min.png"),
        imgvert: require("@/assets/img/projects/alpwise/alpwise-vert.png"),
        imghrz: require("@/assets/img/projects/alpwise/alpwise-hrz.png"),
        imglong: require("@/assets/img/projects/alpwise/alpwise-vert.png"),
        imgdesc: require("@/assets/img/projects/alpwise/alpwise-desc.png"),
        imgbody: [
          require("@/assets/img/projects/alpwise/alpwise-desc.png"),
          require("@/assets/img/projects/alpwise/alpwise1.png"),
          require("@/assets/img/projects/alpwise/alpwise2.png"),
          require("@/assets/img/projects/alpwise/alpwise3.png"),
          require("@/assets/img/projects/alpwise/alpwise4.png"),
        ],
      },
      {
        slug: "portfoliov1",
        id: 6,
        tags: ["HTML", "CSS", "JavaScript","WebDesign"],
        label: "Portfolio V1",
        imgmin: require("@/assets/img/projects/portfoliov1/portfoliov1-min.png"),
        imgvert: require("@/assets/img/projects/portfoliov1/portfoliov1-vert.png"),
        imghrz: require("@/assets/img/projects/portfoliov1/portfoliov1-hrz.png"),
        imglong: require("@/assets/img/projects/portfoliov1/portfoliov1.png"),
        links: [
          {
            label : "Voir le projet",
            url : "https://developpement.bardin-alexandre.fr"
          }
        ],
        imgbody: [
          require("@/assets/img/projects/portfoliov1/portfoliov1-vert.png"),
        ],
      },
      {
        slug: "expert-credit",
        id: 7,
        tags: ["Wordpress","HTML","CSS","JavaScript"],
        label: "L'expert du crédit",
        imgmin: require("@/assets/img/projects/lexpertducredit/lexpertducredit-min.png"),
        imgvert: require("@/assets/img/projects/lexpertducredit/lexpertducredit-vert.png"),
        imghrz: require("@/assets/img/projects/lexpertducredit/lexpertducredit-hrz.png"),
        imglong: require("@/assets/img/projects/lexpertducredit/lexpertducredit-vert.png"),
        imgdesc: require("@/assets/img/projects/lexpertducredit/lexpertducredit-desc.png"),
        imgbody: [
          require("@/assets/img/projects/lexpertducredit/lexpertducredit-desc.png"),
          require("@/assets/img/projects/lexpertducredit/lexpertducredit1.png"),
          require("@/assets/img/projects/lexpertducredit/lexpertducredit2.png"),
          require("@/assets/img/projects/lexpertducredit/lexpertducredit3.png"),
          require("@/assets/img/projects/lexpertducredit/lexpertducredit4.png"),
        ],
      },
      {
        slug: "meubles-montagne",
        id: 8,
        tags: ["Wordpress","HTML","CSS","JavaScript","WebDesign","Plugin Wordpress"],
        label: "Meublés de la montagne",
        imgmin: require("@/assets/img/projects/meubles/meubles-min.png"),
        imgvert: require("@/assets/img/projects/meubles/meubles-vert.png"),
        imghrz: require("@/assets/img/projects/meubles/meubles-hrz.png"),
        imglong: require("@/assets/img/projects/meubles/meubles-vert.png"),
        imgdesc: require("@/assets/img/projects/meubles/meubles-desc.png"),
        imgbody: [
          require("@/assets/img/projects/meubles/meubles-desc.png"),
          require("@/assets/img/projects/meubles/meubles1.png"),
          require("@/assets/img/projects/meubles/meubles2.png"),
          require("@/assets/img/projects/meubles/meubles3.png"),
          require("@/assets/img/projects/meubles/meubles4.png"),
        ],
        links: [
          {
            label : "Voir le projet",
            url : "https://www.meublesdelamontagne.com/"
          }
        ]
      },
      {
        slug: "renover-batir",
        id: 9,
        tags: ["Wordpress","HTML","CSS","JavaScript"],
        label: "Rénover et Batir",
        imgmin: require("@/assets/img/projects/r_b/r_b-min.png"),
        imgvert: require("@/assets/img/projects/r_b/r_b-vert.png"),
        imghrz: require("@/assets/img/projects/r_b/r_b-hrz.png"),
        imglong: require("@/assets/img/projects/r_b/r_b-vert.png"),
        imgdesc: require("@/assets/img/projects/r_b/r_b-desc.png"),
        imgbody: [
          require("@/assets/img/projects/r_b/r_b-desc.png"),
          require("@/assets/img/projects/r_b/r_b1.png"),
          require("@/assets/img/projects/r_b/r_b2.png"),
          require("@/assets/img/projects/r_b/r_b3.png"),
          require("@/assets/img/projects/r_b/r_b4.png"),
        ],
        links: [
          {
            label : "Voir le projet",
            url : "https://www.renoveretbatir.fr/"
          }
        ]
      },
      {
        slug: "memory",
        id: 10,
        tags: ["HTML","CSS","JavaScript","PHP"],
        label: "Memory Game",
        imgmin: require("@/assets/img/projects/memory/memory-min.png"),
        imgvert: require("@/assets/img/projects/memory/memory-vert.png"),
        imghrz: require("@/assets/img/projects/memory/memory-hrz.png"),
        imgbody: [
          require("@/assets/img/projects/memory/memory1.png"),
          require("@/assets/img/projects/memory/memory2.png"),
        ],
        imglong: '',
        links: [
          {
            label : "Voir le projet",
            url : "https://games.bardin-alexandre.fr/memory/"
          }
        ]
      },
      {
        slug: "conceptsport",
        id: 11,
        tags: ["wordpress","CSS","JavaScript","HTML","Plugin Wordpress"],
        label: "Concept Sport Automobiles",
        imgmin: require("@/assets/img/projects/conceptsport/conceptsport.png"),
        imgvert: require("@/assets/img/projects/conceptsport/conceptsport.png"),
        imghrz: require("@/assets/img/projects/conceptsport/conceptsport.png"),
        imgbody: [
          require("@/assets/img/projects/conceptsport/conceptsport2.png"),
          require("@/assets/img/projects/conceptsport/conceptsport1.png"),
          require("@/assets/img/projects/conceptsport/conceptsport3.png"),
          require("@/assets/img/projects/conceptsport/conceptsport4.png"),
          require("@/assets/img/projects/conceptsport/conceptsport5.png"),
        ],
        imglong: '',
      },
      /*{slug: "a", id: 10, tags:[ "Test"], label: "title",imgmin: require('@/assets/img/projects/alpwise.png')},
        {slug: "a", id: 11, tags:["Wordpress", "Test"], label: "title",imgmin: require('@/assets/img/projects/howeasy.png')},
        {slug: "a", id: 12, tags:[ "Test"], label: "title",imgmin: require('@/assets/img/projects/massagesanela.png')},
        {slug: "a", id: 13, tags:["Wordpress", "Test"], label: "title",imgmin: require('@/assets/img/projects/howeasy.png')},
        {slug: "a", id: 14, tags:[ "Test"], label: "title",imgmin: require('@/assets/img/projects/alpwise.png')},
        {slug: "a", id: 15, tags:["Wordpress", "Test"], label: "title",imgmin: require('@/assets/img/projects/howeasy.png')},
        {slug: "a", id: 16, tags:[ "Test"], label: "title",imgmin: require('@/assets/img/projects/massagesanela.png')},
        {slug: "a", id: 17, tags:[ "Test"], label: "title",imgmin: require('@/assets/img/projects/howeasy.png')},
        {slug: "a", id: 18, tags:["Wordpress", "Test", "CSS"], label: "title",imgmin: require('@/assets/img/projects/alpwise.png')}*/
    ],
  },
  mutations: {
    //uniquement MAJ et _
    /*INCREASE_COUNT(state) {
            state.day += 1
        }*/
  },
  actions: {
    /* incrementCount({ commit }) {
            commit('INCREASE_COUNT')
        }*/
  },
  modules: {},
  getters: {
    getSpecificProject: (state) => (id) => {
      return state.projects.find((projects) => projects.id === id);
    },
    getSpecificProjectBySlug: (state) => (slug) => {
      return state.projects.find((projects) => projects.slug === slug);
    },
    getFilters: (state) => {
      let unique = [
        "All",
        ...new Set(state.projects.map((projects) => projects.tags).flat()),
      ];
      return unique;
    },
  },
});
