<template>
    <Nav/>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
</template>

<script>
    import Nav from '@/components/Nav.vue'
    export default {
      name: 'App',
       components: {
        Nav
      }
    }
</script>

