import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    //component: Home,
    meta: {
      title: 'BARDIN-TONNEL Alexandre - Développement web',
      metaTags: [
        {
          name: 'description',
          content: 'BARDIN-TONNEL Alexandre, développeur web au Bourget-du-Lac en Savoie, spécialisé dans la création de site internet. ◕‿‿◕'
        },
        {
          property: 'og:description',
          content: 'BARDIN-TONNEL Alexandre, développeur web au Bourget-du-Lac en Savoie, spécialisé dans la création de site internet sur mesure. ◕‿‿◕'
        }
      ]
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '@/views/Portfolio.vue'),
    meta: {
      title: 'Portfolio de mes réalisations - BARDIN-TONNEL Alexandre',
      metaTags: [
        {
          name: 'description',
          content: 'Venez découvrir les projets réalisés par mes soins - BARDIN-TONNEL Alexandre, développeur web basé aux Bourget-du-Lac ◕‿‿◕'
        },
        {
          property: 'og:description',
          content: 'Venez découvrir les projets réalisés par mes soins - BARDIN-TONNEL Alexandre, développeur web basé aux Bourget-du-Lac ◕‿‿◕'
        }
      ]
    }
  },
  {
    path: '/portfolio/:slug',
    name: 'PortfolioDetails',
    component: () => import(/* webpackChunkName: "portfoliodetails" */ '@/views/Portfolio-details.vue'),
    meta: {
      title: 'BARDIN-TONNEL Alexandre - Projet',
      metaTags: [
        {
          name: 'description',
          content: 'Projet'
        },
        {
          property: 'og:description',
          content: 'Projet'
        },
        {
          name: 'robots',
          content : 'noindex, follow'
        }
      ]
    }
  },
  {
    path: '/cv',
    name: 'Cv',
    component: () => import(/* webpackChunkName: "mentions" */ '@/views/Cv.vue'),
    meta: {
      title: 'CV - BARDIN-TONNEL Alexandre',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        },
        {
          name: 'robots',
          content : 'noindex, follow'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue')
  },
  {
    path: '/mentions-legales',
    name: 'Mentions',
    component: () => import(/* webpackChunkName: "mentions" */ '@/views/Mentions.vue'),
    meta: {
      title: 'Mentions Légales - BARDIN-TONNEL Alexandre',
      metaTags: [
        {
          name: 'description',
          content: 'Mentions Légales du site bardin-alexandre.fr'
        },
        {
          property: 'og:description',
          content: 'Mentions Légales du site bardin-alexandre.fr'
        },
        {
          name: 'robots',
          content : 'noindex, follow'
        }
      ]
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: '404',
    component: () => import(/* webpackChunkName: "error404" */ '@/views/404.vue'),
    meta: {
      title: '404 - BARDIN-TONNEL Alexandre',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        },
        {
          name: 'robots',
          content : 'noindex, follow'
        }
      ]
    } 
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0, behavior: 'smooth',  })
      }, 300)
    })
    //return { top: 0, behavior: 'smooth', }
  },
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    let setTitle = (to.params.slug)? nearestWithTitle.meta.title +' ' +to.params.slug : nearestWithTitle.meta.title;
    document.title = setTitle;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    let isSlugItem = false;
    if(to.params.slug && (tagDef.name == 'description' || tagDef.property == 'og:description')) {
      isSlugItem = true;
    }
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, (isSlugItem && key =="content")?tagDef[key]+' '+ to.params.slug: tagDef[key]);
    });
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
