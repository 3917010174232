<template>
<svg version="1.1" id="Calque_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" class="svg-icon">
<path class="st0" d="M502.3,159.7l-234-156c-8-4.9-16.5-5-24.6,0l-234,156c-6,4-9.7,11.1-9.7,18.3v156c0,7.1,3.7,14.3,9.7,18.3
    l234,156c8,4.9,16.5,5,24.6,0l234-156c6-4,9.7-11.1,9.7-18.3V178C512,170.8,508.3,163.7,502.3,159.7L502.3,159.7z M278,63.1
    L450.3,178l-76.9,51.4L278,165.7V63.1z M234,63.1v102.6l-95.4,63.7L61.7,178L234,63.1z M44,219.1L99.1,256L44,292.8V219.1L44,219.1z
     M234,448.8L61.7,334l76.9-51.4l95.4,63.7V448.8z M256,308l-77.7-52l77.7-52l77.7,52L256,308z M278,448.8V346.3l95.4-63.7l76.9,51.4
    L278,448.8z M468,292.8L412.9,256l55.1-36.9V292.8L468,292.8z"/>
<polygon class="st1" points="107.7,317 371.9,365.2 346,275.6 286.8,291.3 208,185.9 "/>
<rect x="49.3" y="115.2" transform="matrix(0.9848 0.1736 -0.1736 0.9848 48.3431 -40.5647)" class="st2" width="413.3" height="281.7"/>
<polygon class="st2" points="449.2,350.3 484,153.2 442.1,145.8 441.4,127.6 355.8,130.6 76.9,81.4 66.5,140.7 28.4,142 34.6,321.3 
    28,358.8 36,360.2 38.2,423.5 336.1,413.1 435.1,430.6 438.8,409.6 451.3,409.1 "/>
</svg>

</template>

<script>
    export default {
        name: 'PortfolioSVG'
    }
</script>
<style type="text/css">
    .st0{display:none;fill:#72D3E1;stroke:#6CC4C7;stroke-miterlimit:10;}
    .st1{fill:none;stroke:#72D3E1;stroke-width:40;stroke-miterlimit:10;}
    .st2{fill:none;stroke:#72D3E1;stroke-width:39.8948;stroke-miterlimit:10;}
</style>